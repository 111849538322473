import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/interactive/ViewportArea.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/partials/Image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/partials/Shapes.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/Contact.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/Project.tsx");
