"use client";
import { useState, useEffect } from "react";
import { useTheme } from "@hooks/useTheme";
import Preload from "../partials/Preload";

export default function Wrapper({ children }: { children?: React.ReactNode }) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [Theme] = useTheme();
  useEffect(() => {
    console.log({Theme})
    if (Theme) {

      setTimeout(() => {
        setLoading(false);
      }, 500)
      return;
    }
  }, [Theme]);

  return isLoading ? <Preload theme={Theme}/> : <div>{children}</div>;
}
